var data_table_ES =
{
	"sProcessing":     "Procesando...",
	"sLengthMenu":     "Mostrar _MENU_ registros",
	"sZeroRecords":    "No se encontraron resultados",
	"sEmptyTable":     "Ningún dato disponible en esta tabla",
	"sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
	"sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
	"sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
	"sInfoPostFix":    "",
	"sSearch":         "Buscar:",
	"sUrl":            "",
	"sInfoThousands":  ",",
	"sLoadingRecords": "Cargando...",
	"oPaginate": {
		"sFirst":    "Primero",
		"sLast":     "Último",
		"sNext":     "Siguiente",
		"sPrevious": "Anterior"
	},
	"oAria": {
		"sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
		"sSortDescending": ": Activar para ordenar la columna de manera descendente"
	}
};

/* Javascript Slug Generator */
var slug = function(str) {
  	str = str.replace(/^\s+|\s+$/g, ''); // trim
  	str = str.toLowerCase();

  	// remove accents, swap ñ for n, etc
  	var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  	var to   = "aaaaaeeeeeiiiiooooouuuunc------";
  	for (var i=0, l=from.length ; i<l ; i++) {
    	str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  	}

  	str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    	.replace(/\s+/g, '-') // collapse whitespace and replace by -
    	.replace(/-+/g, '-'); // collapse dashes

  	return str;
};

/* Javascript Youtube Validator */
var youtube = function (str) {
    var url = str;
    var flag = false;
    if (url !== undefined || url !== '') {
        var regExp = /^.*([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[0].length == 11) {
            flag = true;
        } else {
            flag = false;
        }
    }
    return flag;
};

/* Javascript Vimeo Validator */
var vimeo = function (str) {
	var url = "http://www.vimeo.com/" + str;
	var flag = false;
	var regExp = /http:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
	var match = url.match(regExp);

	if ((match) && (str.length <= 9) && (str.length >= 6)){
	    flag = true;
	}else{
	    flag = false;
	}
	return flag;
};





$( document ).ready(function() {

	global_events();
	menu_events();
	if ($('body').hasClass('slider')) {
		slider_events();
  	}
	if ($('body').hasClass('escritorio')) {
		escritorio_events();
	}
	if ($('body').hasClass('comprobaciones_formulario')) {
		editar_crear_formulario_events();
	}
	if ($('body').hasClass('piso')) {
		pisos_events();
	}
	if ($('body').hasClass('formulario')) {
		formularios_events();
	}

});

function global_events() {

	/* TINY
	tinymce.init({
	    selector: "textarea",theme: "modern",width: 680,height: 300,
	    plugins: [
	         "advlist autolink link image lists charmap print preview hr anchor pagebreak",
	         "searchreplace wordcount visualblocks visualchars insertdatetime media nonbreaking"
	         //"table contextmenu directionality emoticons paste textcolor responsivefilemanager code"
	   ],
	   toolbar1: "undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | styleselect"
	   //toolbar2: "| responsivefilemanager | link unlink anchor | image media | forecolor backcolor  | print preview code ",
	   //image_advtab: true ,

	   //external_filemanager_path: base_url + "assets/admin/js/responsive-filemanager/filemanager/",
	   //filemanager_title:"Biblioteca Multimedia" ,
	   //external_plugins: { "filemanager" : base_url + "/assets/admin/js/tinymce/plugins/responsivefilemanager/plugin.min.js"}
	 });
	 */

	/* FANCYBOX */
	$('.iframeItem').fancybox({
		'width'		: 900,
		'height'	: 600,
		'minHeight'  : 600,
		'type'		: 'iframe'
	});


	/* COLUMAN EDITABLE*/
	$("td[contenteditable=true]").blur(function(){
        var row_id = $(this).attr("data-id") ;
        var column_row_id = $(this).attr("data-column-id") ;
        var table = $(this).attr("data-table");
        var column = $(this).attr("data-column");
        var value = $(this).text();
        $.post( base_url + "admin/edit_cell",
        	{ id : row_id, column_id: column_row_id, table : table, column : column, value : value })
			.done(function( data ) {
				showPush ('Registro EDITADO Correctamente' , 'success', 4000);
			}
		);
    });

	$("td[contenteditable=true]").keypress(function(event) {
	    if(event.which == 13) {
	    	event.preventDefault();
	        $(this).blur();
	    }
	});

	/* COLOR PICKER */
	$('.colorpicker-component').colorpicker();


	$("body").on("keyup", ".video_link", function (event) {
		var video_id = $(this).val();
		$('.id-video-msg').removeClass('youtube vimeo fail').text('');
		if (youtube(video_id)) {
			$(".add_videocase").removeClass("inactivo");
			$(".add_element").tooltip('destroy');
			$('.id-video-msg').addClass('youtube').text(' - Es un video de Youtube');
		} else if (vimeo(video_id)) {
			$(".add_videocase").removeClass("inactivo");
			$(".add_element").tooltip('destroy');
			$('.id-video-msg').addClass('vimeo').text(' - Es un video de Vimeo');
		} else {
			$(".add_videocase").addClass("inactivo");
			$('.id-video-msg').addClass('fail').text(' - ID de video NO valido');
		}
	});


}

function menu_events () {
	$('#nav_' + $('body').attr('class')).addClass('active');
}





function responsive_filemanager_callback(field_id){

	var botones = $("#"+field_id).closest(".card").find(".add_element");
	if(botones.length > 0){
		botones.eq(0).removeClass("inactivo");
		$(".add_element").tooltip('destroy');
	}

	var new_img = $('#'+field_id).val();
	$('#'+field_id).parent().find(".imagen_preview").css("background-image","url('"+new_img+"?v="+Math.random()+"')");

}


function showPush (msg, tipo, time) {
	$.notify({
    	icon: "pe-7s-config",
    	message: msg

    },{
        type: tipo,
        timer: time
        /*placement: {
            from: from,
            align: align
        }*/
    });
}


function slider_events() {

	var tabla_slider = $("#table_slider").DataTable( {
		language : data_table_ES,
		"bSort" : false,
		"bInfo" : false,
		"bLengthChange": false,
		"bPaginate": false,
		rowReorder: {
			update: false
		}
	});


	tabla_slider.on('row-reorder', function ( e, diff, edit ) {
		var orden_id=[];
		$( "#table_slider .ordenable" ).each(function( index ) {
			var orden = index +1;
			var id = $( this ).data("idslider");
			var elemento = {id:id, orden:orden};
			orden_id.push(elemento);
		  	$( this ).text(orden);
		});

		$.post( base_url + "admin/slider/reordena",
        	{ orden : JSON.stringify(orden_id) })
			.done(function( data ) {
			}
		);
	});

	$(".desactiva_slider").click(function (e) {
		var id_slide_act = $(this).data("idslider");
		var yo = $(this);
		var act = $(this).next();
		$.post( base_url + "admin/desactiva_slide",
        	{ id_slide : id_slide_act })
			.done(function( data ) {
				yo.hide();
				act.show();
				showPush ('slide desactivado, no se mostrará en la página' , 'success', 2000);
			}
		);
	});

	$(".activa_slider").click(function (e) {
		var id_slide_act = $(this).data("idslider");
		var yo = $(this);
		var des = $(this).prev();
		$.post( base_url + "admin/activa_slide",
        	{ id_slide : id_slide_act })
			.done(function( data ) {
				yo.hide();
				des.show();
				showPush ('slide activado, se mostrará en la página' , 'success', 2000);
			}
		);
	});


}

function escritorio_events() {};

function initMap() {

	window.mapEscritorio = new google.maps.Map(document.getElementById('map'), {
		zoom: 10,
		center: {lat: 40.4295074, lng: -3.8045145}
	});
	//window.geocoderEscritorio = new google.maps.Geocoder();
	//console.log(pisos);
	pisos.forEach(element => {
		codeAddress(window.mapEscritorio, element);
	});

}

function codeAddress(map, piso) {
	var location = {lat: parseFloat(piso.latitud), lng: parseFloat(piso.longitud)};
	//console.log(location);
	var infowindow = new google.maps.InfoWindow({
		content: "<div><h5>" + piso.direccion + "</h5><h5>" + piso.piso + "</h5><h5>" + piso.ciudad + ", " + piso.pais + "</h5><a class='btn btn-info btn-fill' href='/admin/formulario/crear/" + piso.id + "'>Crear Informe</a></div>"
	});
	var marker = new google.maps.Marker({
		map: map,
		position: location
	});
	marker.addListener('click', function() {
		infowindow.open(map, marker);
	});
}

function pisos_events() {
	var tabla_formularios = $("#table_pisos").DataTable( {
		language : data_table_ES,
		"order": [[ 0, "desc" ]],
		"columnDefs": [ {
			"targets": 'no-sort',
			"orderable": false,
	  	}],
		"bSort" : true,
		"bInfo" : false,
		"bLengthChange": false,
		"bPaginate": true,
		responsive: {
			details: true
		}
	});

	$( "body" ).on( "click", "span.delete-piso", function( event ) {
		event.preventDefault();
		var r = confirm("¿Estás seguro de borrar el inmueble?");
		if (r == true) {
			window.location.href = $(this).attr('data-href');
		}
	});
}


function formularios_events() {
    var tabla_formularios = $("#table_formularios").DataTable({
        language: data_table_ES,
        "columnDefs": [{
            "targets": 'no-sort',
            "orderable": false,
        }],
        "bSort": true,
        "bInfo": false,
        "bLengthChange": false,
        "bPaginate": true,
		responsive: {
			details: true
		}
	});
	tinymce.init({
		selector: 'textarea'
    });
    //formulario
    $("body").on("click", ".descargar-pdf", function (event) {
        $(this).addClass('disabled');
    });

}

function editar_crear_formulario_events() {

	$("body").on("click", "#toggle-terraza", function (event) {

		if ($('#terraza').hasClass('hide')) {

			$('#terraza').find( "input[value='na']" ).each(function( index ) {
				$(this).click();
			});
			$('#terraza').find( "textarea" ).each(function( index ) {
				$(this).val('');
			});
			$('#terraza_activa').val('ok');
			$('#terraza').removeClass('hide');
			$('#toggle-terraza').text('Desactivar');
			$('#toggle-terraza').removeClass('btn-info').addClass('btn-danger');
			$('#terraza-json').val('{"terraza_activa":"ok", "terraza_puerta":"na","terraza_luces":"na","terraza_tomas_electricas":"na","terraza_suelo":"na","terraza_otras_observaciones":""}');

		} else  {
			$('#terraza').addClass('hide');
			$('#terraza_activa').val('ko');
			$('#toggle-terraza').text('Activar');
			$('#toggle-terraza').removeClass('btn-danger').addClass('btn-info');
			$('#terraza-json').val('{"terraza_activa":"ko", "terraza_puerta":"na","terraza_luces":"na","terraza_tomas_electricas":"na","terraza_suelo":"na","terraza_otras_observaciones":""}');
		}
	});

	$('#galeria_inputfile').dmUploader({
		url: '/admin/upload/formulario/imagen',
		allowedTypes: "image/*",

		onInit: function(){
			console.log('Callback: Plugin initialized');
		},

		onComplete: function() {

		},

		onUploadSuccess: function (id, data) {
			var galeria_items = [];
			$('#galeria_content').append('<div class="col-md-2"><div class="imageAdded"><img src="' + data.fileUploaded + '" /></div> <span class="delImage btn btn-fill btn-danger">Eliminar</span> </div>');

			$( ".imageAdded > img" ).each(function( index ) {
				var element = $(this).attr("src");
				galeria_items.push(element);
			});
			$('#galeria_item').val(JSON.stringify(galeria_items));
			var formGroupJsonElement = $('#galeria_block');
			formElementsToJson (formGroupJsonElement);
			if ($( ".imageAdded > img" ).length >= 6) {
				$('#galeria_inputfile').hide();
			} else {

				$('#galeria_inputfile').show();
			}
		}

		// ... More callbacks
	});

	$( "body" ).on( "click", ".delImage", function (event) {
		var galeria_items = [];
		$(this).parent().remove();
		$( ".imageAdded > img" ).each(function( index ) {
			var element = $(this).attr("src");
			galeria_items.push(element);
		});
		$('#galeria_item').val(JSON.stringify(galeria_items));
		var formGroupJsonElement = $('#galeria_block');
		formElementsToJson (formGroupJsonElement);
		if ($( ".imageAdded > img" ).length >= 6) {
			$('#galeria_inputfile').hide();
		} else {

			$('#galeria_inputfile').show();
		}
	});

	$( "body" ).on( "submit", "#form_crear_formulario", function( event ) {
		if ($(this).hasClass('jsonDataCreated')) {
			return
		}
		event.preventDefault();
		 if (validarFormulario()) {
			var jsonData = prepareJsonDb();
			$('#data-json').val(JSON.stringify(jsonData));
			setTimeout(function(){
				$('#form_crear_formulario').addClass('jsonDataCreated');
				$('#form_crear_formulario').submit();
			}, 1000);



		 }
	  });

	$( "body" ).on( "click", "#add-dormitorio", function( event ) {
		addDormitorio();
	});

	$( "body" ).on( "click", "#add-banio", function( event ) {
		addBanio();
	});

	$("body").on( "change", "input:not([type='hidden']), textarea", function( event ) {

		var formGroupJsonElement = $(this).parent().parent().parent();
		if (formGroupJsonElement.hasClass('bloque-json')) {
			formElementsToJson (formGroupJsonElement);
		}
	});

	addDormitorio();
	addBanio();

}

function formElementsToJson (formGroupJsonElement) {
	var formGroupJsonMapElements = formGroupJsonElement.find('.json-map').val();
	var formGroupJsonMap = JSON.parse(formGroupJsonMapElements);
	var formGroupJsonValues = {};

	console.log(formGroupJsonMap);

	$.each(formGroupJsonMap, function( index, mapItem ) {

		switch (mapItem.type) {
			case "radio":
				formGroupJsonValues[mapItem.name] =  $('[name="' + String(mapItem.name) + '"]:checked').val();
				if ((formGroupJsonValues[mapItem.name] === 'undefined') || (typeof formGroupJsonValues[mapItem.name] === 'undefined')) {
					formGroupJsonValues[mapItem.name] = 'error';
				}
				break;
			case "textarea":
				formGroupJsonValues[mapItem.name] =  $('[name="' + String(mapItem.name) + '"]').val().replace(/\n/gi,"<br>");
				//.val().replace(/\n/gi,"<br>");
				break;
			case "hidden":
				formGroupJsonValues[mapItem.name] =  $('[name="' + String(mapItem.name) + '"]').val();
				break;
			default:
				formGroupJsonValues[mapItem.name] = $('[name="' + String(mapItem.name) + '"]').val();
				break;
		}

	});
	console.log(formGroupJsonValues);
	var jsonString = JSON.stringify(formGroupJsonValues);
	console.log (jsonString);
	formGroupJsonElement.find('.json-element').val(jsonString);
}


function addDormitorio() {
	var n_dormitorios =	$('#dormitorios-content').find('.dormitorio-item').length;
	var html_dormitorio =  dormitorio_template;

	html_dormitorio = html_dormitorio.replace(/{#dormitorio}/i, n_dormitorios + 1);
	html_dormitorio = replaceAll(html_dormitorio, '{#dormitorio}', (n_dormitorios + 1));

	$('#dormitorios-content').append(html_dormitorio);



}



function addBanio() {
	var n_banios =	$('#banios-content').find('.banio-item').length;
	var html_banio =  banio_template;

	html_banio = html_banio.replace(/{#banio}/i, n_banios + 1);
	html_banio = replaceAll(html_banio, '{#banio}', (n_banios + 1));

	$('#banios-content').append(html_banio);


}

function validarFormulario() {

	var validateFlag = true;
	$('.error-bloque').remove();

	$( ".json-element" ).each(function( index ) {
		//console.log(index + ' ' + $(this).attr('id'));
		var jsonElementValue = $(this).val();
		if (jsonElementValue === '') {
			//console.log('error');
			$(this).parent().append('<div class="error-bloque col-md-12">Complete todos los campos</div>');
			validateFlag = false;
		} else {
			var validateItemFlag = true;
			var jsonElementValueObj = JSON.parse(jsonElementValue);
			$.each(jsonElementValueObj, function( index, itemJson ) {
				if (itemJson === 'error') {
					//console.log('error');
					validateItemFlag = false;

				}
			});
			if (!validateItemFlag) {
				validateFlag = false;
				$(this).parent().append('<div class="error-bloque col-md-12">Complete todos los campos</div>');
			}
		}
	});
	console.log(validateFlag);
	return validateFlag;

}


function prepareJsonDb() {
	var jsonDB = {};
	$( ".json-element" ).each(function( index ) {
		var itemKey = $(this).attr('data-map-value');
		jsonDB[itemKey] = JSON.parse($(this).val());
	});
	return jsonDB;
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}
